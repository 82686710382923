import type {
    EstateExpandOpts,
    FormSchema,
    IBaseDDQuestion,
    ICreateMFHParams,
    ICreateMfhEstateRequest,
    IDDAnswer,
    IDDAnswerUpsertParams,
    IDDQuestion,
    IEstate,
    ILocationDTO,
    IMFH,
    IMFHBuilding,
    IMFHPortfolio,
    IMFHSummaryData,
    IMfhBuildingQuery,
    IMfhBuildingRequestParams,
    IMfhFilter,
    IMfhPortfolioQuery,
    IPaginationFields,
    IPipelineMFH,
    IRentalContract,
    MFHBuildingExpandOpts,
    MFHExpandOpts,
    MFHPortfolioExpandOpts,
    MfhPortfolioType,
    RentRollData,
} from '@condo/domain';
import axios from 'axios';
import { getClient } from '../api-client';
import { getSignedUrl, publishFile } from '../basecamp.api';

export const createMFHsViaCSV = async (file: File): Promise<any> => {
    const signedUrl = await getSignedUrl();

    await axios
        .create({
            headers: {
                'Content-Type': file.type,
                'Access-Control-Allow-Origin': '*',
            },
        })
        .put(signedUrl.url, file);

    const { path } = await publishFile(signedUrl.bucketFilename);

    return getClient('basecamp')
        .post('/mfh/csv', { csvURL: path })
        .then(response => response.data);
};

export const fetchMfhList = async (
    filter: IMfhFilter,
    expandOptions?: MFHExpandOpts,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/mfh', { params: { ...filter, expand: expandOptions } })
        .then(response => response.data);

export const fetchMfhSourcingList = async (
    filter?: IMfhFilter,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/mfh-sourcing-list', { params: filter })
        .then(response => response.data);

export const fetchEligibleAssetsList = async (
    filter?: IMfhFilter,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/eligible-assets', { params: filter })
        .then(response => response.data);

export const fetchAssetsInPipelineList = async (
    filter?: IMfhFilter,
): Promise<{
    mfhs: IPipelineMFH[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/assets-in-pipeline', { params: filter })
        .then(response => response.data);

export const fetchOneMfh = async (mfhId: number, expandOptions?: MFHExpandOpts): Promise<IMFH> =>
    getClient('basecamp')
        .post(`/mfh/${mfhId}`, {
            expand: expandOptions,
        })
        .then(response => response.data);

export const getMFHSummary = async (mfhId: number): Promise<IMFHSummaryData> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/summary`)
        .then(response => response.data.mfh);

export const getMfhEstates = async (mfhId: number, expandOptions: EstateExpandOpts): Promise<IEstate[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/estates`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const createMFH = async (body: ICreateMFHParams): Promise<IMFH> =>
    getClient('basecamp')
        .post('/mfh', body)
        .then(response => response.data);

export const createMFHEstate = async (payload: ICreateMfhEstateRequest): Promise<IEstate> =>
    getClient('basecamp')
        .post(`/mfh/buildings/${payload.mfhBuildingId}/estates`, {
            locationId: payload.locationId,
            attributes: payload.attributes,
            rentingStatus: payload.rentingStatus,
            startRenovationProcess: payload.startRenovationProcess,
        })
        .then(response => response.data.estate);

export const updateMFH = async (mfhID: number, body: Partial<IMFH>): Promise<IMFH> =>
    getClient('basecamp')
        .patch(`/mfh/${mfhID}`, body)
        .then(response => response.data);

export const upsertMFHBuildingLocation = async (mfhBuildingId: number, location: ILocationDTO): Promise<void> =>
    getClient('basecamp').put(`/mfh/buildings/${mfhBuildingId}/locations`, { location });

export const upsertMFHBuilding = async (mfhId: number, { mfhBuilding, startRenovationProcess, location }: IMfhBuildingRequestParams): Promise<IMFHBuilding> =>
    getClient('basecamp')
        .put(`/mfh/${mfhId}/buildings/`, { mfhBuilding, startRenovationProcess, location })
        .then(res => res.data.mfhBuilding);

export const deleteMFHBuilding = async (mfhId: number, mfhBuildingId: number): Promise<IMFHBuilding> =>
    getClient('basecamp').delete(`/mfh/${mfhId}/buildings/${mfhBuildingId}`);

export const moveEstatesToBuildingLocation = async (payload: { estateIds: number[]; mfhBuildingId: number }): Promise<void> =>
    getClient('basecamp').patch(`/mfh/estates/move/building/${payload.mfhBuildingId}`, {
        estateIds: payload.estateIds,
    });

export const getMfhDDQuestions = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    mfhId: number,
    type: TSchema,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/dd/questions`, { params: { type } })
        .then(response => response.data.questions);

export const upsertMfhDDQuestion = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    question: IBaseDDQuestion,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    getClient('basecamp')
        .post(`/mfh/${question.mfhId}/dd/questions`, question)
        .then(response => response.data.questions);

export const deleteMfhDDQuestion = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    mfhId: number,
    questionId: number,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    getClient('basecamp')
        .delete(`/mfh/${mfhId}/dd/questions/${questionId}`)
        .then(response => response.data.questions);

export const updateMfhDDAnswer = async (mfhId: number, data: IDDAnswerUpsertParams): Promise<IDDAnswer> =>
    getClient('basecamp')
        .put(`/mfh/${mfhId}/dd/answers`, data)
        .then(response => response.data.answer);

export const getMfhAttributesFromInfas = async (mfhId: number): Promise<void> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/infas-attributes`)
        .then(response => response.data);

export const updateRentRollDD = async (estateId: number, data: RentRollData): Promise<Partial<{ rentalContract: IRentalContract | null }>> =>
    getClient('basecamp')
        .put(`/mfh/estates/${estateId}/rent-roll-dd`, data)
        .then(response => response.data);

export const fetchMfhRentRoll = async (mfhId: number): Promise<RentRollData[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/rent-roll`)
        .then(response => response.data.data);

export const getRentRollImportSheetUrl = async (mfhId: number): Promise<string> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/rent-roll-dd/import-sheet-url`)
        .then(response => response.data.url);

export const parseRentRollFromSheet = async (mfhId: number, spreadsheetUrl: string): Promise<RentRollData[]> =>
    getClient('basecamp')
        .post(`/mfh/${mfhId}/rent-roll-dd/parse`, { spreadsheetUrl })
        .then(response => response.data.data);

export const fetchMfhPortfolioList = async (
    query: IMfhPortfolioQuery,
    expand: MFHPortfolioExpandOpts,
    pagination?: IPaginationFields,
): Promise<{
    mfhPortfolios: IMFHPortfolio[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/mfh-portfolios', { params: { query, expand, pagination } })
        .then(response => response.data);

export const fetchOneMfhPortfolio = async (mfhPortfolioId: number, expandOptions?: MFHPortfolioExpandOpts): Promise<IMFHPortfolio> =>
    getClient('basecamp')
        .get(`/mfh-portfolio/${mfhPortfolioId}`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const createMFHPortfolio = async (payload: { name: string; type: MfhPortfolioType; mfhIds: number[]; assetPartnerId: number }): Promise<IMFHPortfolio> =>
    getClient('basecamp')
        .post('/mfh-portfolio', payload)
        .then(response => response.data);

export const updateMFHPortfolio = async (
    mfhPortfolioId: number,
    payload: {
        name?: string;
        type?: MfhPortfolioType;
        mfhIds?: number[];
    },
): Promise<IMFHPortfolio> =>
    getClient('basecamp')
        .patch(`/mfh-portfolio/${mfhPortfolioId}`, payload)
        .then(response => response.data);

export const fetchMfhBuilding = async (mfhBuildingId: number, expand: MFHBuildingExpandOpts): Promise<IMFHBuilding> =>
    getClient('basecamp')
        .get(`/mfh/buildings/${mfhBuildingId}`, { params: { expand } })
        .then(response => response.data.mfhBuilding);

export const fetchMfhBuildings = async (mfhId: number, expand: MFHBuildingExpandOpts): Promise<IMFHBuilding[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/buildings`, { params: { expand } })
        .then(response => response.data.mfhBuildings);

export const fetchAllMfhBuildings = async (query: IMfhBuildingQuery, expand: MFHBuildingExpandOpts, pagination?: IPaginationFields): Promise<IMFHBuilding[]> =>
    getClient('basecamp')
        .get(`/mfh-buildings`, { params: { query, expand, pagination } })
        .then(response => response.data);

export const fetchMfhHierarchy = async (mfhId: number): Promise<IMFH> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/mfh-hierarchy`)
        .then(response => response.data);
