import type { NavigationGuard } from 'vue-router';
import type { Store } from 'vuex';
import type { ICockpitStore } from '../store';

export const getDefaultFilterQuery = (query?: Record<string, any>): Record<string, any> => {
    if (!query) {
        return {};
    }
    return {
        ...query,
        ...(query.page ? { page: +query.page } : {}),
        ...(query.size ? { size: +query.size } : {}),
        ...(query.skip ? { skip: +query.skip } : {}),
        ...(query.limit ? { limit: +query.limit } : {}),
    };
};

export const isAdminGuard =
    (store: Store<ICockpitStore>): NavigationGuard =>
    async () => {
        const isAdmin = store.getters.isAdmin;
        if (isAdmin) {
            return true;
        }
        return { path: '/unauthorized' };
    };
