export default {
    general: {
        buttonActionGoBack: 'Go Back',
        buttonActionCancel: 'Cancel',
        buttonActionSubmit: 'Submit',
    },
    authorization: {
        enterViaOnelogin: 'Enter via OneLogin',
        emailAndPassword: 'Email and password',
        externalUsersOnly: 'External users only',
        emailInputPlaceholder: 'Your email',
        passwordInputPlaceholder: 'Your password',
        errorNoEmailPassword: 'Please provide Email and password',
        errorWrongEmailPassword: 'Wrong email or password provided',
        forgotPassword: 'Forgot password?',
        buttonSignIn: 'Sign In',
        forgotPasswordDescription: 'Please fill in the email you registered your account with. If the account exists, we will send a reset link.',
        forgotPasswordTitle: 'Forgot Password?',
        sendResetLink: 'Reset password',
        errorPasswordReset: 'There was an error while resetting your password. Please try again later',
        resetLinkSent: 'Password reset link has been sent to your email.',
        backToLogin: 'Back to login',
    },
};
