import { createSellingInvestmentCase } from '@/api/basecamp.api';
import { copyInvestmentCase, getInvestmentById, getInvestments } from '@/api/finance.api';
import { MessageType, showErrorMessage, showSnackbar } from '@/shared/messages';
import type { ICockpitStore } from '@/store';
import {
    type IConfigObject,
    type IEstate,
    type IInvestment,
    type IInvestmentCalcInputs,
    type IInvestmentCaseRecalculationOpts,
    InvestmentCase,
    type InvestmentCaseType,
    ReviewProcess,
} from '@condo/domain';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export interface IInvestmentStore {
    data: InvestmentCase[];
    total: number;
    currentInvestment: InvestmentCase | null;
    originalInvestment: InvestmentCase | null;
    parentPrediction: {};
}

function getInitialState(): IInvestmentStore {
    return {
        data: [],
        total: 0,
        currentInvestment: null,
        originalInvestment: null,
        parentPrediction: null,
    };
}

export interface IGetInvestment {
    predictionId?: number;
    estateId?: number;
    investmentId?: number;
    withReviewProcess?: boolean;
}

// initial state
const state = getInitialState();

const getters: GetterTree<IInvestmentStore, any> = {
    all(state) {
        return state.data;
    },
    count(state) {
        return state.total;
    },
    currentInvestment(state) {
        return state.currentInvestment;
    },
    originalInvestment(state) {
        return state.originalInvestment;
    },
    parentPrediction(state) {
        return state.parentPrediction;
    },
};

const actions: ActionTree<IInvestmentStore, ICockpitStore> = {
    async loadInvestmentData({ commit }, params: IGetInvestment) {
        return getInvestmentById(params.investmentId, {
            withReviewProcess: params.withReviewProcess,
        }).then(investment => {
            commit('setCurrentInvestment', { investment: new InvestmentCase(investment as unknown as Partial<IInvestment>) });
            commit('setParentPrediction', { parentPrediction: investment.predictionResult });
        });
    },

    async getInvestmentCases(
        { commit },
        params: Partial<IInvestment> & { investmentCaseTypes?: InvestmentCaseType[] } = {
            predictionId: undefined,
            estateId: undefined,
        },
    ) {
        const { predictionId, estateId, investmentCaseTypes } = params;

        return getInvestments({ estateId, predictionId, investmentCaseTypes })
            .then(investments => {
                const investmentCases = investments.map(investment => {
                    const reviewProcess = investment.reviewProcess ? new ReviewProcess(investment.reviewProcess) : null;
                    return new InvestmentCase({ ...(investment as unknown as Partial<IInvestment>), reviewProcess });
                });
                commit('setInvestmentCases', { investments: investmentCases, total: investments.length });
                return investmentCases;
            })
            .catch(error => {
                commit('setInvestmentCases', { investments: [], total: 0 });
                showErrorMessage({ message: `Error during fetching investment-cases: ${error.message}` });
            });
    },
    async createSellingInvestmentCase(_store, { investment, distributionType }) {
        return createSellingInvestmentCase(investment, distributionType);
    },
    async copyInvestmentCase(_store, { investmentCaseId, copyInvestmentCaseType }: { investmentCaseId: number; copyInvestmentCaseType?: InvestmentCaseType }) {
        return copyInvestmentCase(investmentCaseId, copyInvestmentCaseType);
    },
};

const mutations: MutationTree<IInvestmentStore> = {
    setInvestmentCases(state, { investments, total }: { investments: []; total: number }) {
        state.data = investments;
        state.total = total;
    },
    addInvestmentCase(state, investment) {
        state.data.push(investment);
    },
    setCurrentInvestment(state, { investment }: { investment: InvestmentCase }) {
        state.currentInvestment = investment;
        state.originalInvestment = investment.copy();
    },
    setParentPrediction(state, { parentPrediction }) {
        state.parentPrediction = parentPrediction;
    },
    restoreInvestment(state) {
        state.currentInvestment = state.originalInvestment.copy();
    },
    calculateInvestmentCase(
        state,
        { values, config, estate, opts }: { values: IInvestmentCalcInputs; config?: IConfigObject; estate: IEstate; opts: IInvestmentCaseRecalculationOpts },
    ) {
        try {
            state.currentInvestment.calculateInvestment(
                {
                    values,
                    estate,
                    inputs: config.Inputs,
                    tables: config.LookupTable,
                },
                opts,
            );
        } catch (e: any) {
            showSnackbar(e.message, MessageType.Danger, 5000);
        }
    },
    setPickingReasonOnInvestment(state, pickingReason: string) {
        state.currentInvestment = new InvestmentCase({
            ...state.currentInvestment,
            pickingReason,
        });
    },
};

export const investmentCasesStore: Module<IInvestmentStore, any> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
