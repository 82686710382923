import type {
    ContactExpandOpts,
    ContactType,
    IContactPerson,
    IContactPersonRelation,
    IContactResponse,
    IContactSingleResponse,
    IFetchContactParams,
    OneloginDepartment,
} from '@condo/domain';
import { getClient } from './api-client';

export const saveContact = async (contact: Partial<IContactPerson>, relation?: Partial<IContactPersonRelation>): Promise<IContactPerson> => {
    if (contact.contactId) {
        return getClient('basecamp')
            .put(`/contacts/${contact.contactId}`, { contact })
            .then(response => response.data.contact);
    }
    return getClient('basecamp')
        .post('/contacts', { contact, relation })
        .then(response => response.data.contact);
};

export const createEmptyContact = async (emptyContact: { email: string; department: OneloginDepartment }): Promise<IContactPerson> => {
    return getClient('basecamp')
        .post('/contacts/create-empty', emptyContact)
        .then(response => response.data.contact);
};

export const createExternalUser = async (contact: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    contactType: ContactType;
    companyId: number;
}): Promise<IContactPerson> => {
    return getClient('basecamp')
        .post('/contacts/create-external-user', contact)
        .then(response => response.data.contact);
};
export const importContactsFromFirebase = async (): Promise<IContactPerson[]> => {
    return getClient('basecamp')
        .post('/contacts/import-from-firebase')
        .then(response => response.data.contacts);
};

export const getContacts = async (params: IFetchContactParams): Promise<IContactResponse> =>
    getClient('basecamp')
        .get('/contacts', { params })
        .then(response => response.data);

export const getOneContact = async (contactId: number, expandOpts?: ContactExpandOpts): Promise<IContactSingleResponse> =>
    getClient('basecamp')
        .get(`/contacts/${contactId}`, { params: { expand: expandOpts } })
        .then(response => response.data);

export const contactsMerge = async (contact: IContactPerson, contacts: IContactPerson[]): Promise<IContactSingleResponse> =>
    getClient('basecamp')
        .put(`/contacts/${contact.contactId}/merge`, { contacts })
        .then(response => response.data);

export const deleteContact = async (contactId: number): Promise<void> => getClient('basecamp').delete(`/contacts/${contactId}`);

export const getUserContact = async (uid: string): Promise<IContactSingleResponse> =>
    getClient('basecamp')
        .get(`/users/${uid}/contact`)
        .then(res => res.data);

export const assignContact = async (data: Pick<IContactPerson, 'estateId' & 'contactId' & 'companyFunction'>): Promise<{ relation: IContactPerson }> =>
    getClient('basecamp')
        .post('/contact-relation', data)
        .then(res => res.data.relation);
