import { updateUser } from '@/api/basecamp.api';
import { getUserDetails } from '@/api/user.api';
import type { IActionParams } from '@/types';
import type { CompanyFunction, ContactType, IPermission, IUser } from '@condo/domain';
import { omit } from 'lodash-es';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IUserStore {
    favourites: string[];
    userCounters: UsersCounters;
    roles: string[];
    user: {
        userId: string;
        email: string;
        firstname: string;
        lastname: string;
        displayName: string;
        activeRole: string;
        permissions?: IPermission[];
        companyFunction?: CompanyFunction;
        contactType?: ContactType;
    } | null;
    typesenseConfig: {
        url: string;
        apiKey: string;
    };
}

export interface UsersCounters {
    estatesCount: number;
    reviewRequests: number;
    taskCount: number;
}

function getInitialState(): IUserStore {
    const favs = localStorage.getItem('estates:favourites');
    return {
        user: null,
        roles: [],
        favourites: favs ? JSON.parse(favs) : [],
        userCounters: {
            estatesCount: 0,
            reviewRequests: 0,
            taskCount: 0,
        } as UsersCounters,
        typesenseConfig: {
            url: '',
            apiKey: '',
        },
    };
}

const state = getInitialState();

const getters: GetterTree<IUserStore, any> = {
    loggedIn(state): boolean {
        return !!state.user;
    },
    user(state: IUserStore): IUser {
        return state.user as unknown as IUser;
    },
    isAdmin(state: IUserStore): boolean {
        return state.roles.some(r => r === 'admin');
    },
    userRoles(state: IUserStore): string[] {
        return state.roles;
    },
    favourites(state: IUserStore) {
        return state.favourites;
    },
    userCounters(state: IUserStore): UsersCounters {
        return state.userCounters;
    },
    userTotalCounters(state: IUserStore): number {
        //TODO: change to sum
        return state.userCounters.reviewRequests;
    },
    getTypeseneConfig(state: IUserStore) {
        return state.typesenseConfig;
    },
};

const actions: ActionTree<IUserStore, any> = {
    async updateFirebaseUser({ state }: IActionParams<IUserStore>) {
        const lastSync = localStorage.getItem('user:sync:date');
        if (lastSync && new Date().getTime() - new Date(+lastSync).getTime() < 60 * 60 * 24 * 1000) {
            return;
        }

        await updateUser(state.user.userId, {
            firstName: state.user.firstname,
            lastName: state.user.lastname,
            displayName: [state.user.firstname, state.user.lastname].filter(Boolean).join(' '),
        });
        localStorage.setItem('user:sync:date', `${new Date().getTime()}`);
    },
    async getUserDetails({ commit }): Promise<IUser> {
        const res = await getUserDetails('me');

        const userDisplayName = res.user.displayName ?? [res.user.firstName ?? '', res.user.lastName ?? ''].filter(Boolean).join(' ');
        commit('setUserCounters', omit(res, ['user']));
        commit('setUser', {
            userId: res.user.uid,
            email: res.user.email,
            firstname: res.user.firstName,
            lastname: res.user.lastName,
            displayName: res.user.displayName,
            activeRole: res.user.activeRole ?? res.user.customClaims?.activeRole,
            permissions: res.user.permissions,
            companyFunction: res.user.companyFunction,
            contactType: res.user.contactType,
        });
        commit('setPermissions', res.user.permissions);
        commit('setUserDisplayName', userDisplayName);
        commit('setCompanyFunction', res.user.companyFunction);
        commit('setContactType', res.user.contactType);
        const userRoles = res.user.roles?.map(r => r.role?.name).filter(Boolean) ?? [];
        commit('setRoles', userRoles);
        commit('setTypesenseConfig', {
            url: res.typesense?.url ?? '',
            apiKey: res.typesense?.apiKey ?? '',
        });
        //TODO: Remove this HAXX introduced for hiding errors from MFH Investor (KO-6109)
        (window as any).userRoles = userRoles;
        return res.user as IUser;
    },
};

const mutations: MutationTree<IUserStore> = {
    setCompanyFunction(state, companyFunction: CompanyFunction) {
        state.user.companyFunction = companyFunction;
    },
    setUser(state, user) {
        state.user = user;
    },
    setRoles(state, roles) {
        state.roles = roles;
    },
    setContactType(state, contactType) {
        state.user.contactType = contactType;
    },
    markFavourites(state, estateId) {
        state.favourites.push(estateId);
        localStorage.setItem('estates:favourites', JSON.stringify(state.favourites));
    },
    removeFavourites(state, estateId) {
        state.favourites.splice(
            state.favourites.findIndex(id => id === estateId),
            1,
        );
        localStorage.setItem('estates:favourites', JSON.stringify(state.favourites));
    },
    setUserCounters(state: IUserStore, data) {
        state.userCounters = data;
    },
    setPermissions(state: IUserStore, permissions: IPermission[]) {
        state.user.permissions = permissions;
    },
    setUserDisplayName(state: IUserStore, displayName: string) {
        state.user.displayName = displayName;
    },
    setTypesenseConfig(state: IUserStore, typesenseConfig: { url: string; apiKey: string }) {
        state.typesenseConfig = typesenseConfig;
    },
};

export const userStore = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
