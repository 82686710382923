import { createRouter, createWebHistory } from 'vue-router';

const getRouter = () =>
    createRouter({
        history: createWebHistory(),
        linkActiveClass: 'open active',
        scrollBehavior(to) {
            if (to.hash) {
                return new Promise(resolve => {
                    setTimeout(() => {
                        resolve({
                            el: to.hash,
                            top: 100,
                            behavior: 'smooth',
                        });
                    }, 1000);
                });
            }

            return { top: 0 };
        },
        routes: [
            {
                name: 'Home',
                path: '/',
                component: () => import('@/external/views/ExternalHome.vue'),
            },
            {
                name: 'AssetsList',
                path: '/assets',
                meta: {
                    title: 'Assets List',
                    icon: 'list-dropdown',
                },
                component: () => import('@/external/views/ExternalAssetList.vue'),
            },
            {
                name: 'InvoicesList',
                path: '/invoices',
                meta: {
                    title: 'Invoices List',
                    icon: 'file-invoice-dollar',
                },
                component: () => import('@/external/views/ExternalInvoicesList.vue'),
            },
            {
                name: 'Calendar',
                path: '/calendar',
                meta: {
                    title: 'Calendar',
                    icon: 'calendar',
                },
                component: () => import('@/external/views/ExternalCalendar.vue'),
            },
            {
                name: 'DataRoom',
                path: '/data-room',
                meta: {
                    title: 'Data Room',
                    icon: 'document',
                },
                component: () => import('@/external/views/ExternalDataRoom.vue'),
            },
            {
                name: 'OneMFH',
                path: '/mfhs/:mfhId',
                props: true,
                component: () => import('@/external/views/ExternalOneMfh.vue'),
                redirect: params => {
                    return `${params.fullPath}/details`;
                },
                meta: {
                    title: 'Asset Details',
                    icon: 'circle-info',
                },
                children: [
                    {
                        name: 'MFHDetails',
                        path: 'details',
                        component: () => import('@/external/components/mfh/ExternalMfhDetails.vue'),
                        props: true,
                    },
                    {
                        name: 'one.mfh.summary',
                        path: 'summary',
                        component: () => import('@/external/views/ExternalMfhSummary.vue'),
                        props: true,
                    },
                    {
                        name: 'MFHDocuments',
                        path: 'documents',
                        component: () => import('@/external/components/mfh/ExternalMfhDocuments.vue'),
                        props: true,
                    },
                    {
                        name: 'MFHChecklists',
                        path: 'checklists',
                        component: () => import('@/external/components/mfh/ExternalMfhChecklists.vue'),
                        props: true,
                    },
                    {
                        name: 'MFHMedias',
                        path: 'medias',
                        component: () => import('@/external/components/mfh/ExternalMfhMedias.vue'),
                        props: true,
                    },
                    {
                        name: 'MFHAssetUnits',
                        path: 'units',
                        component: () => import('@/external/components/mfh/ExternalMfhUnits.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: '/:catchAll(.*)*',
                component: () => import('@/external/views/ExternalNotFound.vue'),
            },
        ],
    });

export const router = getRouter();
