import { useQuery } from '@tanstack/vue-query';

import { fetchUsers } from '@/api/basecamp.api';
import type { IContactPerson, IPermission } from '@condo/domain';
import { difference } from 'lodash-es';
import { computed } from 'vue';
import { store } from '../store';

export const useUsers = () => {
    return useQuery(['users'], fetchUsers, {
        initialData: [],
    });
};

// @note this is always set as it is set during bootstrap phase
export const useSelfUser = () => {
    return computed(() => store.getters.user as IContactPerson);
};

export const useHasPermissions = () => {
    const user = useSelfUser();
    const hasPermission = (...requiredPermissions: IPermission[]) => {
        if (!user.value) {
            return false;
        }
        const missingPermissions = difference(requiredPermissions, (user.value as any).permissions);

        return missingPermissions.length === 0;
    };
    return {
        hasPermission,
    };
};
