import type { App } from 'vue';
import { createI18n, useI18n } from 'vue-i18n';
import en from './locales/en';
type MessageSchema = typeof en;
export type NumberSchema = {
    currency: {
        style: 'currency';
        currencyDisplay: 'symbol';
        currency: string;
    };
};
export const useTranslation = () => {
    return useI18n<{ message: MessageSchema; number: NumberSchema }, 'en'>();
};

export const initI18n = (app: App) => {
    const locales = { en };
    type LocaleKey = keyof typeof locales;
    const defaultLocale: LocaleKey = 'en';
    const i18n = createI18n({
        locale: defaultLocale,
        fallbackLocale: 'en',
        messages: locales,
        warnHtmlInMessage: 'off',
        legacy: false,
    });
    app.use(i18n);
};
