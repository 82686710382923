import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';
import { getDefaultFilterQuery } from './route-helpers';

const CCSRouteMap = getStrippedCCSRoutes();

export const operationRoutes: RouteRecordRaw[] = [
    {
        ...CCSRouteMap['operation.list.monitoring'],
        component: () => import('@/components/operation/MonitoringList.vue'),
        props: route => ({ filter: getDefaultFilterQuery(route.query) }),
    },
];
