import { fetchTaskRelations } from '@/api/basecamp.api';
import { getMFHBuildingProcess, getMFHProcess } from '@/api/mfh';
import { getMFHPortfolioProcess } from '@/api/mfh/mfh-portfolio-process.api';
import { useRouteParams } from '@/hooks/util.hooks';
import { CCSRouteMap, MFHBuildingProcessType, MFHPortfolioProcessType, MFHProcessType, resolveRoutePath } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';

export const notificationRoute: RouteRecordRaw = {
    ...CCSRouteMap['notification'],
    component: () => null,
    async beforeEnter(to, _from, next) {
        const { getRouteParam } = useRouteParams(to);
        const t = getRouteParam('t', String);

        switch (t) {
            case 'note-mfh-sourcing-process': {
                const mfhSourcingProcessId = getRouteParam('mfhSourcingProcessId', String);
                if (mfhSourcingProcessId) {
                    const process = await getMFHProcess({
                        processType: MFHProcessType.Sourcing,
                        processId: +mfhSourcingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh.sourcing.overview'], {
                            params: { processId: mfhSourcingProcessId.toString(), mfhId: process.mfhId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-purchasing-process': {
                const mfhPurchasingProcessId = getRouteParam('mfhPurchasingProcessId', String);
                if (mfhPurchasingProcessId) {
                    const process = await getMFHProcess({
                        processType: MFHProcessType.Purchasing,
                        processId: +mfhPurchasingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh.purchasing.overview'], {
                            params: { processId: mfhPurchasingProcessId.toString(), mfhId: process.mfhId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-subsidy-financing-process': {
                const mfhSubsidyFinancingProcessId = getRouteParam('mfhSubsidyFinancingProcessId', String);
                if (mfhSubsidyFinancingProcessId) {
                    const process = await getMFHProcess({
                        processType: MFHProcessType.SubsidyFinancing,
                        processId: +mfhSubsidyFinancingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh.subsidy-financing.overview'], {
                            params: { processId: mfhSubsidyFinancingProcessId.toString(), mfhId: process.mfhId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-estate-management-process': {
                const mfhEstateManagementProcessId = getRouteParam('mfhEstateManagementProcessId', String);
                if (mfhEstateManagementProcessId) {
                    const process = await getMFHProcess({
                        processType: MFHProcessType.EstateManagement,
                        processId: +mfhEstateManagementProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh.estate-management.overview'], {
                            params: { processId: mfhEstateManagementProcessId.toString(), mfhId: process.mfhId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-owner-management-process': {
                const mfhOwnerManagementProcessId = getRouteParam('mfhOwnerManagementProcessId', String);
                if (mfhOwnerManagementProcessId) {
                    const process = await getMFHProcess({
                        processType: MFHProcessType.OwnerManagement,
                        processId: +mfhOwnerManagementProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh.owner-management.overview'], {
                            params: { processId: mfhOwnerManagementProcessId.toString(), mfhId: process.mfhId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-building-renovation-process': {
                const mfhBuildingRenovationProcessId = getRouteParam('mfhBuildingRenovationProcessId', String);
                if (mfhBuildingRenovationProcessId) {
                    const process = await getMFHBuildingProcess({
                        processType: MFHBuildingProcessType.Renovation,
                        processId: +mfhBuildingRenovationProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-building.renovation.overview'], {
                            params: { processId: mfhBuildingRenovationProcessId.toString(), mfhBuildingId: process.mfhBuildingId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-building-estate-management-process': {
                const mfhBuildingEstateManagementProcessId = getRouteParam('mfhBuildingEstateManagementProcessId', String);
                if (mfhBuildingEstateManagementProcessId) {
                    const process = await getMFHBuildingProcess({
                        processType: MFHBuildingProcessType.EstateManagement,
                        processId: +mfhBuildingEstateManagementProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-building.estate-management.overview'], {
                            params: { processId: mfhBuildingEstateManagementProcessId.toString(), mfhBuildingId: process.mfhBuildingId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-portfolio-financing-process': {
                const mfhPortfolioFinancingProcessId = getRouteParam('mfhPortfolioFinancingProcessId', String);
                if (mfhPortfolioFinancingProcessId) {
                    const process = await getMFHPortfolioProcess({
                        processType: MFHPortfolioProcessType.Financing,
                        processId: +mfhPortfolioFinancingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-portfolio.financing.overview'], {
                            params: { processId: mfhPortfolioFinancingProcessId.toString(), mfhPortfolioId: process.mfhPortfolioId.toString() },
                        }),
                    );
                    return;
                }

                break;
            }
            case 'note-mfh-portfolio-purchasing-process': {
                const mfhPortfolioPurchasingProcessId = getRouteParam('mfhPortfolioPurchasingProcessId', String);
                if (mfhPortfolioPurchasingProcessId) {
                    const process = await getMFHPortfolioProcess({
                        processType: MFHPortfolioProcessType.Purchasing,
                        processId: +mfhPortfolioPurchasingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-portfolio.purchasing.overview'], {
                            params: { processId: mfhPortfolioPurchasingProcessId.toString(), mfhPortfolioId: process.mfhPortfolioId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-portfolio-selling-process': {
                const mfhPortfolioSellingProcessId = getRouteParam('mfhPortfolioSellingProcessId', String);
                if (mfhPortfolioSellingProcessId) {
                    const process = await getMFHPortfolioProcess({
                        processType: MFHPortfolioProcessType.Selling,
                        processId: +mfhPortfolioSellingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-portfolio.selling.overview'], {
                            params: { processId: mfhPortfolioSellingProcessId.toString(), mfhPortfolioId: process.mfhPortfolioId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'task': {
                const taskId = getRouteParam('taskId', Number);
                if (taskId) {
                    const task = await fetchTaskRelations(+taskId);
                    if (task.estateId) {
                        next(
                            resolveRoutePath(CCSRouteMap['to-do'], {
                                params: { estateId: task.estateId.toString() },
                            }),
                        );
                        return;
                    }
                    if (task.mfhId) {
                        next(
                            resolveRoutePath(CCSRouteMap['one.mfh.tasks'], {
                                params: { mfhId: task.mfhId.toString() },
                            }),
                        );
                        return;
                    }
                    if (task.mfhBuildingId) {
                        next(
                            resolveRoutePath(CCSRouteMap['one.mfh-building.tasks'], {
                                params: { mfhBuildingId: task.mfhBuildingId.toString() },
                            }),
                        );
                        return;
                    }
                    if (task.mfhPortfolioId) {
                        next(
                            resolveRoutePath(CCSRouteMap['one.mfh-portfolio.tasks'], {
                                params: { mfhPortfolioId: task.mfhPortfolioId.toString() },
                            }),
                        );
                        return;
                    }
                }
                break;
            }
            default:
                next('/not-found');
        }
    },
};
