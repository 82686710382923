import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';
import type { Store } from 'vuex';
import type { ICockpitStore } from '../store';
import { isAdminGuard } from './route-helpers';

const CCSRouteMap = getStrippedCCSRoutes();

export const configRoutes = (store: Store<ICockpitStore>): RouteRecordRaw => ({
    ...CCSRouteMap.config,
    redirect: params => `${params.fullPath}/${CCSRouteMap['purchasing-regions'].path}`,
    component: () => import('@/views/Config.vue'),
    children: [
        {
            ...CCSRouteMap['purchasing-regions'],
            component: () => import('@/views/Regions.vue'),
        },
        {
            ...CCSRouteMap['selling-regions'],
            component: () => import('@/views/SellingRegions.vue'),
        },
        {
            ...CCSRouteMap['region-detail'],
            component: () => import('@/views/OneRegion.vue'),
            props: route => ({ regionId: +route.params.regionId }),
        },
        {
            ...CCSRouteMap['renovation.construction-templates'],
            components: {
                default: () => import('@/components/construction/ConstructionTemplateList.vue'),
            },
        },
        {
            ...CCSRouteMap['construction-templates.one'],
            component: () => import('@/components/construction/ConstructionTemplateDetails.vue'),
            props: route => ({ constructionTemplateId: +route.params.constructionTemplateId }),
        },
        {
            ...CCSRouteMap['renovation.catalogue'],
            components: {
                default: () => import('@/components/construction/ConstructionCatalogue.vue'),
            },
        },
        {
            ...CCSRouteMap['renovation.catalogue.detail'],
            components: {
                default: () => import('@/components/construction/details/CatalogueItemDetailsPage.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['renovation.catalogue.pricing'],
            components: {
                default: () => import('@/components/construction/CataloguePricing.vue'),
            },
        },
        {
            ...CCSRouteMap['loan.accounts.list'],
            components: {
                default: () => import('@/components/finance/LoanAccountsList.vue'),
            },
        },
        {
            ...CCSRouteMap['rules.editor'],
            components: {
                default: () => import('@/components/configuration/rules/Rules.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['permissions.roles.editor'],
            components: {
                default: () => import('@/components/configuration/permissions/RolesOverview.vue'),
            },
        },
        {
            ...CCSRouteMap['permissions.users.editor'],
            components: {
                default: () => import('@/components/configuration/permissions/PermissionUsers.vue'),
            },
        },
        {
            ...CCSRouteMap['admin.cache.management'],
            components: {
                default: () => import('@/components/system/CacheManagement.vue'),
            },
            beforeEnter: [isAdminGuard(store)],
        },
        {
            ...CCSRouteMap['admin.audit-log'],
            components: {
                default: () => import('@/components/system/audit-log/AuditLog.vue'),
            },
            beforeEnter: [isAdminGuard(store)],
        },
        {
            ...CCSRouteMap['admin.pipeline-audit-events'],
            components: {
                default: () => import('@/components/system/pipeline-audit-events/PipelineAuditEvents.vue'),
            },
            beforeEnter: [isAdminGuard(store)],
        },
        {
            ...CCSRouteMap['admin.general'],
            components: {
                default: () => import('@/components/system/GeneralAdmin.vue'),
            },
            beforeEnter: [isAdminGuard(store)],
        },
        {
            ...CCSRouteMap['admin.email-templates'],
            components: {
                default: () => import('@/components/configuration/EmailTemplates.vue'),
            },
        },
        {
            ...CCSRouteMap['admin.notification-settings'],
            components: {
                default: () => import('@/components/configuration/NotificationSettings.vue'),
            },
        },
        {
            ...CCSRouteMap['admin.task-templates'],
            components: {
                default: () => import('@/components/configuration/task/TaskTemplates.vue'),
            },
        },
        {
            ...CCSRouteMap['loan.accounts.list'],
            components: {
                default: () => import('@/components/ComingSoon.vue'),
            },
        },
        {
            ...CCSRouteMap['admin.invoice-settings'],
            components: {
                default: () => import('@/components/configuration/InvoiceSettings.vue'),
            },
        },
        {
            ...CCSRouteMap['inspection.checklist'],
            components: {
                default: () => import('@/components/configuration/InspectionChecklist.vue'),
            },
        },
    ],
});
