import {
    type AnyProcess,
    type CompanyOrContactRelation,
    type CondoUuidWithExtraAttrsDeserialized,
    ConfigRecord,
    type DistributionType,
    type DocumentExportType,
    type DocumentType,
    type EventExpandOpts,
    type IAttributesDTO,
    type ICalendarEvent,
    type ICalendarEventCreateParams,
    type ICalendarEventSearchParams,
    type ICockpitBootstrapData,
    type ICompanyEstateRelation,
    type ICondoEvent,
    type IConfigData,
    type IConfigHistory,
    type IConfigObject,
    type IConfigRecord,
    type IConfigSearchParams,
    type ICostTypesMapping,
    type IDocUploadResult,
    type IDocument,
    type IDocumentExport,
    type IDocumentGenerationParams,
    type IEstate,
    type IEstatesResponse,
    type IExternalData,
    type IExternalDataRequestParams,
    type IFetchEstate,
    type IFetchEstateUuidsQuery,
    type IFetchEstatesParams,
    type IFetchEventParams,
    type IFetchPredictionsParams,
    type IFetchTaskParams,
    type IFindDocumentsReq,
    type IFindOneEstate,
    type IFindTransactionMonitoringEstatesParams,
    type IGenerateDocumentSyncParams,
    type IGetDocumentParams,
    type IGetSignedUrlsParams,
    type IGoogleCalendarsList,
    type IInvestment,
    type ILocationDTO,
    type ILocationDictionaries,
    type INote,
    type IOverviewEstatesQuery,
    type IPaginatedResponse,
    type IPaginationFields,
    type IParkingSpace,
    type IPrediction,
    type IProcess,
    type IRenovationOverview,
    type ISignedUrlParams,
    type ISourcingEstatesQuery,
    type ITask,
    type ITaskResponse,
    type IUpdateTaskStatusParams,
    type IUploadAndSaveFileResult,
    type IUser,
    InvestmentCase,
    type NoteExpandOpts,
    type NoteType,
    type ProcessType,
    PurchasingProcess,
    type TaskExpandOpts,
    bootstrapDataUserCompressor,
    condoUUIDCompressor,
} from '@condo/domain';
import axios from 'axios';
import { omit } from 'lodash-es';
import { getClient } from './api-client';

// todo: when the other api methods are extracted, remove this
export * from './renovation.api';

export const resetCache = async (opts: { cacheKeys?: string[]; kind?: 'here' | 'rpc' | 'repo' | 'usecase'; prefix?: string }): Promise<void> =>
    getClient('basecamp').post('/admin/reset-cache', opts);

/**
 * Estate API
 */
export const findEstates = async (params?: IFetchEstatesParams): Promise<IEstatesResponse> =>
    getClient('basecamp')
        .get('/estates', { params })
        .then(response => response.data);

export const findTransactionMonitoringEstates = async (params?: IFindTransactionMonitoringEstatesParams): Promise<IEstatesResponse> =>
    getClient('basecamp')
        .get('/estates/transaction-monitoring', { params })
        .then(response => response.data);

export const findSourcingEstates = async (params?: ISourcingEstatesQuery): Promise<IEstatesResponse> =>
    getClient('basecamp')
        .get('/sourcing/estates', { params })
        .then(response => response.data);

export const findOverviewEstates = async (params?: IOverviewEstatesQuery): Promise<IEstatesResponse> =>
    getClient('basecamp')
        .get('/overview/estates', { params })
        .then(response => response.data);

export const fetchLinkedEstates = async ({ groupId }: { groupId: string }): Promise<IEstatesResponse> =>
    getClient('basecamp')
        .get(`/estate-group/${groupId}`)
        .then(response => response.data);

export const fetchOneEstate = async (estateId, params?: IFetchEstate): Promise<IEstate> => {
    return getClient('basecamp')
        .get(`/estates/${estateId}`, { params })
        .then(response => response.data);
};

export const findOneEstate = async (params?: IFindOneEstate): Promise<IEstate> => {
    return getClient('basecamp')
        .post(`/estates/find-one`, params)
        .then(response => response.data);
};
export const maintenanceDeleteEstate = async (estateId): Promise<void> => {
    return getClient('basecampMaintenance').post('/maintenance/delete-estate', { estateId });
};

export const fetchAllEstateCondoUuids = async (query: IFetchEstateUuidsQuery): Promise<CondoUuidWithExtraAttrsDeserialized[]> => {
    const data = await getClient('basecamp')
        .get('/estate-fields/condo-uuids', { params: query })
        .then(response => response.data.condoUuids);

    return condoUUIDCompressor.decompress(data);
};

export const fetchEstateIdByCondoUuid = async (condoUuid: string): Promise<{ estateId: number }> =>
    getClient('basecamp')
        .get(`/estates/${condoUuid}/estate-id`)
        .then(response => response.data.estateId);

export const fetchEstateIdByOriginalId = async (originalId: string): Promise<{ estateId: number }> =>
    getClient('basecamp')
        .get(`/estates/original-id/${originalId}/estate-id`)
        .then(response => response.data.estateId);

export const reCalculate = async (estateId: number): Promise<{}> => getClient('basecamp').post(`/estates/${estateId}/recalculate`);

export const linkEstateManually = async (estateId: number, toLink: { estateId: number }): Promise<{ groupId: string }> =>
    getClient('basecamp')
        .post(`/estates/${estateId}/link`, toLink)
        .then(response => response.data);

export const unlinkEstateManually = async (estateId: number): Promise<{}> => getClient('basecamp').delete(`/estates/${estateId}/link`);

export const updateEstate = async (estateId: number, body: Partial<IEstate>): Promise<IEstate> =>
    getClient('basecamp')
        .patch(`/estates/${estateId}`, body)
        .then(response => response.data);

export const updateEstateLocation = async (estateId: number, location: ILocationDTO): Promise<IEstate> =>
    getClient('basecamp')
        .patch(`/estates/${estateId}/location`, { location })
        .then(response => response.data.estate);

export const linkEstateLocation = async (estateId: number, locationId: number): Promise<IEstate> =>
    getClient('basecamp')
        .patch(`/estates/${estateId}/location/${locationId}`)
        .then(response => response.data.estate);

export const updateEstateAttributes = async (
    estateId: number,
    attributes: IAttributesDTO,
    isPostAttributes: boolean,
    mergeWithExisting: boolean,
): Promise<IEstate> =>
    getClient('basecamp')
        .patch(`/estates/${estateId}/attributes`, { attributes, isPostAttributes, mergeWithExisting })
        .then(response => response.data.estate);

export const fetchExternalData = async (params: IExternalDataRequestParams): Promise<IExternalData[]> =>
    getClient('basecamp')
        .get('/external-data', { params })
        .then(response => response.data);

export const triggerEstateValuation = async (estateId: number) =>
    getClient('basecamp')
        .post(`/external-valuation/${estateId}`)
        .then(response => response.data);

/* Predictions */
export const getPredictions = async (estateId: number, params?: IFetchPredictionsParams): Promise<{ predictions: IPrediction[]; total: number }> =>
    getClient('basecamp')
        .get(`/estates/${estateId}/prediction-results`, { params })
        .then(response => response.data);

export const getPredictionById = async (estateId: number | string, predictionId: number | string): Promise<IPrediction> =>
    getClient('basecamp')
        .get(`/estates/${estateId}/prediction-results/${predictionId}`)
        .then(response => response.data);

// Investment Case
export const createPurchasingInvestmentCase = async (params): Promise<IInvestment> => {
    const { estateId, predictionId, name } = params;
    return getClient('basecamp')
        .post(`/estates/${estateId}/prediction-results/${predictionId}/investments/purchasing`, { name })
        .then(response => response.data);
};

export const createSellingInvestmentCase = async (params, distributionType?: DistributionType): Promise<IInvestment> => {
    const { estateId, predictionId, investmentId } = params;
    return getClient('basecamp')
        .post(`/estates/${estateId}/prediction-results/${predictionId}/investments/${investmentId}/selling`, { distributionType })
        .then(response => (response.data ? new InvestmentCase(response.data) : null));
};

/* Notes */
export const addNote = async (note: Partial<INote>): Promise<INote> =>
    getClient('basecamp')
        .post('/notes', note)
        .then(response => response.data.note);
export const editNote = async (noteId: number, body: string): Promise<INote> =>
    getClient('basecamp')
        .put(`/notes/${noteId}`, { body })
        .then(response => response.data.note);

export const removeNote = async (noteId: number): Promise<{ result: boolean }> => getClient('basecamp').delete(`/notes/${noteId}`);

export const fetchNotes = async (
    where: Partial<INote> & { type?: NoteType[] | NoteType; omitCraftNotes?: boolean; renovationInspectionsIds?: number[] },
    expand?: NoteExpandOpts,
): Promise<INote[]> =>
    getClient('basecamp')
        .get('/notes', { params: { ...where, expand } })
        .then(response => response.data.notes);
/*Documents*/
export const uploadFile = async (formdata: FormData, params: {}, url = '/file'): Promise<IDocUploadResult[]> =>
    getClient('basecamp')
        .post(url, formdata, { params, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => response.data);

export const getDocument = async (documentId: number, params: { expand: IGetDocumentParams['expand'] }): Promise<IDocument[]> => {
    return getClient('basecamp')
        .get(`/document/${documentId}`, { params })
        .then(response => response.data);
};

export const findDocuments = async (queryString: IFindDocumentsReq): Promise<IDocument[]> =>
    getClient('basecamp')
        .get('/document', { params: queryString })
        .then(response => response.data);

export const linkFile = async (fileData: {}): Promise<IUploadAndSaveFileResult[]> =>
    getClient('basecamp')
        .post('/file/link', fileData)
        .then(response => [response.data]);

export const publishFile = async (bucketFilename: string): Promise<{ path: string }> =>
    getClient('basecamp')
        .get(`/file/${bucketFilename}`)
        .then(response => response.data);

export const updateDocument = async (doc: Partial<IDocument>): Promise<IDocument> =>
    getClient('basecamp')
        .patch('/document', doc)
        .then(response => response.data.document);

export const deleteDocument = async (uuid: string): Promise<{}> =>
    getClient('basecamp')
        .delete(`/document`, { params: { uuid } })
        .then(response => response.data);

export const getSignedUrl = async (params?: Partial<ISignedUrlParams>): Promise<{ bucketFilename: string; url: string }> =>
    getClient('basecamp')
        .get('/file/signed-url', { params })
        .then(response => response.data);

export const deleteDocumentById = async (documentId: number): Promise<{}> =>
    getClient('basecamp')
        .delete(`/document/${documentId}`)
        .then(response => response.data);

export const getSignedUrls = async (
    files: IGetSignedUrlsParams['Body']['files'],
): Promise<
    {
        bucketFilename: string;
        url: string;
    }[]
> =>
    getClient('basecamp')
        .post('/file/signed-urls', { files })
        .then(response => response.data);

export const uploadFilesWithSignedUrls = async (files: File[]): Promise<{ bucketFilename: string; url: string }[]> => {
    const signedUrls = await getSignedUrls(
        files.map(el => ({ fileName: el.name, contentType: el.type, extensionHeaders: { 'x-goog-meta-filename': encodeURI(el.name) } })),
    );
    const uploadableFiles = files.map((file, index) => ({ file, signedUrl: signedUrls[index] }));

    await Promise.all(
        uploadableFiles.map(({ file, signedUrl }) =>
            axios.put(signedUrl.url, file, {
                headers: {
                    'Content-Type': file.type,
                    'Access-Control-Allow-Origin': '*',
                    'x-goog-meta-filename': encodeURI(file.name),
                },
            }),
        ),
    );

    return signedUrls;
};

export const getSignedDownloadUrl = async (uuid, filename): Promise<{ bucketFilename: string; url: string }> =>
    getClient('basecamp')
        .get(`/file/signed-url?uuid=${uuid}&action=read&version=v2&filename=${encodeURIComponent(filename)}`)
        .then(response => response.data);

/*Generic*/
export const fetchConfigRecord = async (attributes?: IConfigSearchParams): Promise<IConfigRecord[]> => {
    return getClient('basecamp')
        .get('/config-records', { params: attributes })
        .then(response => response.data.records);
};

export const fetchActiveConfigRecords = async (): Promise<IConfigRecord[]> => {
    return getClient('basecamp')
        .get('/config-records/active')
        .then(response => response.data.records);
};

export const fetchConfigById = async (configId: string): Promise<ConfigRecord> => {
    return getClient('basecamp')
        .get(`/config-records/encodedIds/${configId}`)
        .then(response => new ConfigRecord(response.data));
};

export const fetchActiveConfigAttributesPaths = async (attributes: IConfigSearchParams): Promise<string[]> => {
    return getClient('basecamp')
        .get('/config-records/list', { params: attributes })
        .then(response => response.data);
};

export const fetchConfigHistory = async (attributePath: string): Promise<IConfigHistory[]> => {
    return getClient('basecamp')
        .get(`/config-records/history/${encodeURIComponent(attributePath)}`)
        .then(response => response.data);
};

export const fetchConfigWithParentByVersion = async (version: number): Promise<{ config: IConfigObject; parent?: IConfigObject }> => {
    return getClient('basecamp')
        .get(`/config-records/${version}`)
        .then(response => response.data);
};

export const saveConfigRecords = async (configRow: Partial<IConfigData>, attributePath: string): Promise<IConfigHistory> =>
    getClient('basecamp')
        .patch('/config-records', { configRow, attributePath })
        .then(response => response.data);

export const getDictionaries = async () =>
    getClient('basecamp')
        .get('/dictionaries')
        .then(res => res.data);

export const getLocationDictionaries = async (names: (keyof ILocationDictionaries)[]): Promise<Partial<ILocationDictionaries>> =>
    getClient('basecamp')
        .get(`/dictionaries/${names.join(',')}`)
        .then(res => res.data);

export const getCostTypeMappings = async (): Promise<Record<string, ICostTypesMapping>> =>
    getClient('basecamp')
        .get(`/dictionaries/costtype-mappings`)
        .then(res => res.data);

export const fetchBootstrapData = async (): Promise<ICockpitBootstrapData> => {
    const compressedData = await getClient('basecamp')
        .get('/dictionaries/bootstrap-data')
        .then(res => res.data);

    const users = bootstrapDataUserCompressor.decompress(compressedData.users);
    const data = { ...compressedData, users } as ICockpitBootstrapData;

    return data;
};

/*Tasks API*/
type ITaskData = Partial<ITask>;

export const fetchTasks = async (params: IFetchTaskParams): Promise<ITaskResponse> =>
    getClient('basecamp')
        .get('/tasks', { params })
        .then(res => res.data);

export const fetchTask = async (taskId: number, expandOpts: TaskExpandOpts): Promise<ITask> =>
    getClient('basecamp')
        .get(`/tasks/${taskId}`, { params: { expand: expandOpts } })
        .then(res => res.data.task);

export const fetchTaskRelations = async (taskId: number): Promise<ITask> =>
    getClient('basecamp')
        .get(`/tasks/${taskId}/relations`)
        .then(res => res.data.task);

export const createTask = async (task: ITaskData): Promise<ITask> =>
    getClient('basecamp')
        .post('/tasks', task)
        .then(r => r.data.task);

export const updateTask = async (task: ITask): Promise<ITask> =>
    getClient('basecamp')
        .patch(`/tasks/${task.taskId}`, task)
        .then(r => r.data.task);

export const updateTaskStatus = async (payload: IUpdateTaskStatusParams): Promise<ITask> =>
    getClient('basecamp')
        .patch(`/tasks/${payload.taskId}/status`, omit(payload, 'taskId'))
        .then(r => r.data.task);

export const deleteTask = async (taskId: number): Promise<boolean> =>
    getClient('basecamp')
        .delete(`/tasks/${taskId}`)
        .then(res => res.data);

/* Calendar Events */

export const fetchGoogleCalendarsList = async (): Promise<IGoogleCalendarsList> =>
    getClient('basecamp')
        .get('/calendar-event/google-calendars-list/')
        .then(res => res.data);

export const fetchCalendarEvents = async (params?: ICalendarEventSearchParams): Promise<ICalendarEvent[]> =>
    getClient('basecamp')
        .get('/calendar-event', { params })
        .then(res => res.data);

export const createCalendarEvent = async (body: ICalendarEventCreateParams): Promise<ICalendarEvent> =>
    getClient('basecamp')
        .post('/calendar-event', body)
        .then(res => res.data);

export const updateCalendarEvent = async (body: ICalendarEvent): Promise<ICalendarEvent> =>
    getClient('basecamp')
        .put(`/calendar-event/${body.calendarEventId}`, body)
        .then(res => res.data);
export const deleteCalendarEvent = async (calendarEventId): Promise<boolean> =>
    getClient('basecamp')
        .delete(`/calendar-event/${calendarEventId}`)
        .then(res => res.data);

/*Events*/
export const fetchEvents = async (
    query: IFetchEventParams,
    expand?: EventExpandOpts,
    pagination?: IPaginationFields,
): Promise<IPaginatedResponse<ICondoEvent>> =>
    getClient('basecamp')
        .post('/events', { query, expand, pagination })
        .then(res => res.data);

/*Processes*/
export const fetchProcess = async <T extends IProcess = IProcess>(processId: number | string, processType: ProcessType): Promise<T> =>
    getClient('basecamp')
        .get(`/processes/${processType}/${processId}`)
        .then(res => res.data.process);

export const updateProcess = async (processId: number | string, processType: ProcessType, process: Partial<AnyProcess>): Promise<IProcess> =>
    getClient('basecamp')
        .patch(`/processes/${processType}/${processId}`, process)
        .then(res => res.data.process);

export const getProcessSummary = async (processId: number | string, processType: ProcessType): Promise<IRenovationOverview> =>
    getClient('basecamp')
        .get(`/processes/${processType}/${processId}/summary`)
        .then(res => res.data.summary);

export const postProcessEvent = async (processType: ProcessType, processId: number, event: string, eventDate: Date, data: {}): Promise<{}> =>
    getClient('basecamp').post(`/processes/${processType}/${processId}/event`, { event, eventDate, additionalData: { ...data } });

export const startProcess = async (body: { estateId: number; processType: ProcessType; skipStartEvents?: boolean }): Promise<IProcess> =>
    getClient('basecamp')
        .post('/processes', body)
        .then(response => response.data.process);

/*Processes*/
export const fetchProcesses = async <T extends IProcess = IProcess>(params: object, mapToClass = false): Promise<{ processes: T[]; total: number }> =>
    getClient('basecamp')
        .get('/processes', { params })
        .then(res => {
            if (mapToClass) {
                return { processes: res.data.processes.map(proc => new PurchasingProcess(proc)), total: res.data.total };
            }
            const { processes, total } = res.data;
            return { processes, total };
        });

export const createEstate = async (body: Partial<IEstate>): Promise<IEstate> =>
    getClient('basecamp')
        .post('/estates', body)
        .then(response => response.data);

/*Users Section*/
export const fetchUsers = async (): Promise<IUser[]> =>
    getClient('basecamp')
        .get('/users')
        .then(res => res.data.users);

export const updateUser = async (uid: string, user: Partial<IUser>): Promise<IUser> =>
    getClient('basecamp')
        .patch(`/users/${uid}`, user)
        .then(res => res.data.user);

export const assignCompanyToEstate = async ({
    companyId,
    estateId,
    relationName,
}: {
    companyId: number;
    estateId: number;
    relationName: CompanyOrContactRelation;
}): Promise<ICompanyEstateRelation> =>
    getClient('basecamp')
        .post(`/company-estate`, {
            companyId,
            estateId,
            relationName,
        })
        .then(res => res.data.companyEstateRelation);

export const removeContactAssignment = async (contactRelationId: number): Promise<void> =>
    getClient('basecamp').delete(`/contact-relation/${contactRelationId}`);

export const generateDocumentSync = async (params: IDocumentGenerationParams<any>): Promise<IDocument> =>
    getClient('basecamp')
        .post('/documents/sync', params)
        .then(r => r.data.document);

export const getDocumentTemplate = async (documentType: DocumentType, estateId?: number): Promise<any> =>
    getClient('basecamp')
        .get(`/document-template/${documentType}`, {
            params: { estateId },
        })
        .then(r => {
            return { data: r.data };
        });

export const generateDocumentFromPayload = async (payload: IGenerateDocumentSyncParams, format: 'xlsx' | 'doc' | 'pdf'): Promise<any> =>
    getClient('basecamp')
        .post(
            '/document/generate',
            { ...payload, format },
            {
                responseType: 'blob',
                headers: {
                    Accept: {
                        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        doc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        pdf: 'application/pdf',
                    }[format],
                },
            },
        )
        .then(r => {
            return { data: r.data, headers: r.headers };
        });

export const generatePdfFromImages = async (images: string[]): Promise<any> =>
    getClient('basecamp')
        .post(
            '/documents/convert/images-pdf',
            { images },
            {
                responseType: 'blob',
            },
        )
        .then(r => {
            return { data: r.data, headers: r.headers };
        });

export const getParamsFormForDocument = async (params: { estateId: number; documentType: string }) =>
    getClient('basecamp')
        .get(`/estate/${params.estateId}/document/${params.documentType}`)
        .then(r => r.data);

export const getLatestDocumentExportByType = async (type: DocumentExportType): Promise<IDocumentExport> =>
    getClient('basecamp')
        .get('/document-exports', { params: { type } })
        .then(response => response.data.documentExport);

export const searchDistrictCourts = async (name: string): Promise<string[]> =>
    getClient('basecamp')
        .get(`/estates/attributes/district-courts`, { params: { name } })
        .then(res => res.data);

export const updateDocumentRelation = async (
    documentRelationId: number,
    body: {
        estateId: number;
        documentId: number;
        rentTenancyId?: number | null;
        investorInfoId?: number | null;
        serviceChargeStatementId?: number | null;
    },
): Promise<void> => getClient('basecamp').put(`/document-relation/${documentRelationId}`, body);

export const upsertParkingSpace = async (parkingSpace: Partial<IParkingSpace>): Promise<IParkingSpace> =>
    getClient('basecamp')
        .post('/parking-spaces', { parkingSpace })
        .then(response => response.data.parkingSpace);

export const getParkingSpaces = async (estateId: number): Promise<IParkingSpace[]> =>
    getClient('basecamp')
        .get('/parking-spaces', { params: { estateId } })
        .then(response => response.data.parkingSpaces);

export const deleteParkingSpace = async (parkingSpaceId: number): Promise<void> => getClient('basecamp').delete(`/parking-spaces/${parkingSpaceId}`);

export const getPMDocumentsTracker = async (params: any) => {
    return getClient('basecamp')
        .get('/property-management/documents-tracker', { params })
        .then(res => res.data);
};
